<template>
  <div class="goodslist-row">
    <van-card
      :title="item.title"
      :thumb="item.img"
      v-for="item in goodsList"
      :key="item.id"
      :lazy-load="true"
      @click="goGoodsDetail(item.id)"
    >
      <!-- <p slot="price" class="showPriceStyle">￥{{(item.price / 100).toFixed(2)}}</p> -->
      <p slot="price" class="showPriceStyle">￥{{ item.showPrice}}</p>
      <p slot="origin-price" v-if="item.discountMoney">￥{{item.minMoney}}</p>
      <p
        slot="bottom"
        class="scoreStyle"
        v-if="item.score"
      >{{item.score}}积分</p>
    </van-card>
  
  </div>
</template>

<script>
import { Card } from "vant";
export default {
  data() {
    return {
      goGoodsDetail: this.Base.goGoodsDetail
    };
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    [Card.name]: Card
  }
};
</script>

<style lang="less" scoped>
.goodslist-row {
  width: 100%;
  box-sizing: border-box;
  padding: 0 .24rem /* 12/50 */;
  .van-card {
    background-color: #fff;
    padding: 8px 5px 8px 8px;
    border-radius: .16rem /* 8/50 */;
    box-shadow: 0px 0px 15px #dadce3;
    &:not(:first-child) {
      margin-top: 5px;
    }
    .van-card__desc {
      font-size: .22rem /* 11/50 */;
      letter-spacing: 1px;
      color: #bcbcbc;
    }
    .van-card__thumb {
      width: 110px;
      height: 110px;
      .van-image {
        /deep/ .van-image__img {
          border-radius: .06rem /* 3/50 */;
        }
      }
    }
    .van-card__content {
      box-sizing: border-box;
      padding: .12rem /* 6/50 */;
      .van-card__title {
        max-height: 38px;
        line-height: .38rem /* 19/50 */;
        font-size: .28rem /* 14/50 */;
        color: #131111;
        overflow: hidden;
        margin: 0 0 .32rem /* 16/50 */;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: rows;
        -webkit-line-clamp: 2;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
      }
    }
  }
}
.showPriceStyle {
  font-size: 0.32rem;
  color: @price_text_color;
}
.scoreStyle {
  font-size: 0.22rem;
  letter-spacing: 1px;
  color: @score_text_color;
}
</style>