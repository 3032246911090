<template>
    <section class="secondclass-header dark_heador">
        <span class="iconfont icon-fanhui" style="color:#535353" @click="goBack"></span>
        <div @click="goSearch">
            <span class="iconfont icon-sousuo"></span>
        </div>
        <!-- <p>
            <span class="iconfont icon-xiaoxi" style="color:#333" @click="$router.push('/customerChat')"></span>
        </p> -->
            <!-- <router-link to="/customerChat">消息</router-link> -->
    </section>
</template>
<script>
    export default {
        components: {},
        data() {
            return {
                isfixed: false
            };
        },
        created() {
            // 获取消息数量
            // this.getNewsnumber();
        },
        mounted() {
            // 获取元素距离最初距离顶部的位置距离
            this.offsetTop = document.querySelector(".secondclass-header").offsetTop;
            // 开启滚动监听
            window.addEventListener("scroll", this.handleScroll);
        },
        methods: {
            // 滚动监听  滚动触发的效果写在这里
            handleScroll() {
                // 获取滚动条距离顶部的位置
                var scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                // 滚动条距离与元素顶部距离进行比较
                if (scrollTop > this.offsetTop) {
                    this.isfixed = true;
                } else {
                    this.isfixed = false;
                }
            },
            // 跳转到搜索页面
            goSearch() {
                this.$router.push("/search");
            },
            // 获取消息数量
            getNewsnumber() {
                var loginToken = localStorage.getItem("token");
                this.$server
                    .newsNumAddress({
                        loginToken
                    })
                    .then(res => {
                        if (res.code == 100) {
                            this.newsnum = res.returnValue;
                        }
                    });
            },
            // 返回
            goBack() {
                this.$router.back(0);
            }
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll); // 离开页面 关闭监听 不然会报错
        }
    };
</script>
<style lang="less">
    @red: #d84b41;
    @import "../../assets/styles/element.less";
    .secondclass-header {
        width: 100%;
        box-sizing: border-box;
        padding: .16rem /* 8/50 */ .32rem /* 16/50 */;
        position: fixed;
        top: 0;
        z-index: 999;
        // background-color: #d84b41;
        .list(row);
        height: .96rem /* 48/50 */;
        align-items: center;
        font-size: .24rem /* 12/50 */;
        justify-content: space-between;
        color: #fff;

        & > div {
            width: 80%;
            // width: 100%;
            padding-left: .76rem /* 38/50 */;
            border-radius: .32rem /* 16/50 */;
            // background-color: #fff;
            position: relative;
            // height: .5rem /* 25/50 */;
            border: 1px solid #fff;
            line-height: .5rem /* 25/50 */;
            color: rgb(155, 154, 154);
            // border: 1px solid @red;

            background: #F6F6F9;
            border-radius: .2rem;
            height: .7rem;


            span {
                position: absolute;
                top: .1rem;
                color: #ccc;
                // font-weight: bold;
                right: .2rem;
                font-size: .4rem /* 20/50 */;
            }
        }

        & > p {
            text-align: center;
            color: #fff;

            span {
                display: block;
                font-size: .44rem /* 22/50 */;
            }

            a {
                color: #fff;
            }
        }
    }
</style>



