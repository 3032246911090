<template>
  <div class="categroy-img-goods" :style="bgColor">
    <transition name="fade">
      <preLoading v-if="isLoading"></preLoading>
    </transition>
    <!-- 头部 -->
    <van-nav-bar
      :title="categoryName"
      left-arrow
      @click-left="clickLeft"
      :border="false"
      fixed
      :z-index="100"
      class="category-img-goods-head"
      :style="{ backgroundColor: isShowBgc ? headerBgc : 'rgba(0, 0, 0, 0)' }"
    />
    <!-- <p class="tip-img">
      <img :src="tipImg" />
    </p> -->
    <!-- <section class="categroy-mansion" v-if="categoryList.length">
      <div class="categroy-mansion-img">
        <p
          v-for="item in categoryList"
          :key="item.id"
          @click="goCurrage(item.id, item.categoryName)"
        >
          <img :src="item.imageURL" :alt="item.categoryName" />
        </p>
      </div>
      <h2 class="categroy-mansion-title">精选推荐</h2>
    </section> -->
    <section class="categroy-mansion" v-if="iconUrl">
      <div class="categroy-mansion-img">
        <p>
          <img :src="iconUrl" alt="" />
        </p>
      </div>
      <!-- <h2 class="categroy-mansion-title">精选推荐</h2> -->
    </section>
    <div>
      <!-- <img :src="imgbk" alt="" style="width: 100%" /> -->
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$globalConfig.finishedText"
      @load="onLoad"
      :error.sync="error"
      offset:100
      ref="goodsList"
      :immediate-check="checkis"
      :class="{ vertival: vertical }"
    >
      <van-row gutter="15" v-if="vertical">
        <van-col span="12" v-for="item in goodsList" :key="item.id">
          <ul @click="goGoodsDetail(item.id)">
            <li>
              <img v-lazy="item.img" />
            </li>
            <li style="margin: 0.16rem 0 0; max-height: 40px">
              {{ item.title }}
            </li>
            <li style="display: flex; margin: 0">
              <span class="showPriceStyle"
                >￥{{ item.showPrice}}</span
              >
              <!-- <span class="showPriceStyle"
                >￥{{ (item.price / 100).toFixed(2) }}</span
              > -->
              
              <!-- <span
                style="
                  color: #999;
                  font-size: 0.22rem;
                  text-decoration: line-through;
                "
                v-if="item.discountMoney"
                >￥{{ item.minMoney }}</span
              > -->
            </li>
            <!-- <li
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0;
              "
            >
              <span
                style="color: #999; font-size: 0.22rem; letter-spacing: 1px"
              >
                已售{{item.soldNum}}
              </span>
              <span
                style="
                  color: #999;
                  font-size: 0.22rem;
                  letter-spacing: 1px;
                  color: #c93f3f;
                "
                v-if="item.score"
                >{{ item.score }}积分</span
              >
            </li> -->
          </ul>
        </van-col>
      </van-row>
      <goods-row :goodsList="goodsList" v-else></goods-row>
      <!-- <p slot="loading">
        <img
          src="../../assets/images/index.messenger-typing-preloader.svg"
          alt
          style="width: 20%"
        />
      </p> -->
      <loading-infinity slot="loading"></loading-infinity>
    </van-list>
  </div>
</template>

<script>
import Heador from "../secondclassfication/secondheader";
import goodsRow from "@/components/public/goodsRow";
import { Row, Col, List, NavBar } from "vant";
export default {
  data() {
    return {
      goodsList: [],
      tipImg: null,
      categoryName: null,
      pageNo: 1,
      loading: true,
      finished: false,
      error: false,
      totalPage: Number,
      checkis: false,
      goGoodsDetail: this.Base.goGoodsDetail,
      isShowBgc: false,
      headerBgc: null,
      footerBgc: null,
      imageBgc: null,
      isLoading: true,
      categoryList: [], // 公馆分类
      vertical: false,
      // 临时变量 判断是否为中秋专区
      ismiddle: false,
      // 背景色  0:国庆 中秋背景色 1新品推荐
      colorType: 0,
      iconUrl: this.$route.query.iconUrl,
      categoryName: this.$route.query.categoryName,
      bgColor: "",
      imgbk: this.$route.query.img,
    };
  },
  components: {
    Heador,
    goodsRow,
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [NavBar.name]: NavBar,
  },
  created() {
    this.colorType = this.$route.query.colorType;
    this.changeBgColor();
    this.getNewCategoryList();
  },
  mounted() {
    // 获取元素距离最初距离顶部的位置距离
    this.offsetTop = document.querySelector(
      ".category-img-goods-head"
    ).offsetTop;
    // 开启滚动监听
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    // 监听相同路由下参数变化的时候，从而实现异步刷新
    $route(to, from) {
      // 继续获取第一页数据
      this.getData();
      // 重新恢复触发下拉刷新条件
      this.currentPage = 1;
      this.totalPage = Number;
      this.finished = false;
      this.loading = true;
      this.tipImg = null;
      this.isLoading = true;
      this.categoryList = [];
    },
  },
  computed: {},
  methods: {
    changeBgColor() {
      // { backgroundColor: colorType==0?'#9D0000' :colorType==1?'#F0B222': footerBgc }
      switch (this.categoryName) {
        case "疯狂特卖":
          this.bgColor = { backgroundColor: "rgb(205, 228, 177)" };
          break;
        case "精品旅游":
          this.bgColor = { backgroundColor: "rgb(57, 187, 143)" };
          break;
        case "扶贫专区":
          this.bgColor = { backgroundColor: "rgb(210, 235, 88)" }; //linear-gradient(rgb(75, 179, 132), #fff)
          break;
        case "新品种草":
          this.bgColor = { backgroundColor: "rgb(83, 154, 152)" };
          break;
        case "进口好物":
          this.bgColor = { backgroundColor: "rgb(74, 159, 123)" };
          break;
      }
    },
    // 滚动监听  滚动触发的效果写在这里
    handleScroll() {
      // 获取滚动条距离顶部的位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      // 滚动条距离与元素顶部距离进行比较
      if (scrollTop > this.offsetTop) {
        this.isShowBgc = true;
      } else {
        this.isShowBgc = false;
      }
    },
    clickLeft() {
      this.$router.back();
    },
    //
    goCurrage(categoryId, categoryName) {
      this.$router.push(
        `/categoryImgGoods/${categoryId}?categoryName=${categoryName}&pageName=公馆分类`
      );
    },
    // 获取第一页的数据
    getData() {
      this.categoryName = this.$route.query.categoryName;
      const FLAG = Boolean(this.$route.query.categoryId); // 主题精选
      const Vert = Boolean(this.$route.query.doubleTwelve); // 年货街
      const isMansion = this.$route.query.pageName === "公馆分类"; // 进口好物三级界面
      let categoryType = this.$route.params.categoryType;
      let categoryId = this.$route.params.categoryType;
      let requestAddress = "";
      // 请求地址
      if (Vert) {
        requestAddress = "newYearThirdAddress";
        this.isShowBgc = true;
        this.vertical = false;
      } else if (FLAG) {
        requestAddress = "topicSelectSecondAddress";
        this.vertical = false;
      } else if (isMansion) {
        this.isShowBgc = true;
        // 进口好货三级
        this.$api.home
          .goodsListAddress({
            categoryId,
            currentPage: 1,
          })
          .then((res) => {
            if (res.code === 100) {
              this.loading = false;
              this.isLoading = false;
              this.headerBgc = "#AAD3FF";
              this.footerBgc = "#AAD3FF";

              this.vertical = true;
              this.totalPage = res.returnValue.totalPage;
              this.goodsList = res.returnValue.data;
            }
          });
        return false;
      } else {
        switch (categoryType) {
          case "14":
          case "12":
            requestAddress = "importGoodsSecondAddress";
            this.isShowBgc = false;
            this.vertical = true;
            break;
          case "10":
          case "6":
          case "17":
          case "9":
          case "19":
            requestAddress = "goodsByTypeAddress";
            this.vertical = false;
            this.isShowBgc = false;
            break;
          case "11":
            requestAddress = "tourismAddress";
            this.vertical = false;
            this.isShowBgc = false;
            break;
        }
      }
      this.loading = true;
      this.$server[requestAddress]({
        categoryType,
        categoryId,
        currentPage: 1,
        accessToken: localStorage.getItem("token"),
      }).then((res) => {
        if (res.code === 100) {
          this.isLoading = false;
          this.loading = false;
          this.categoryList = res.returnValue.category || [];
          this.tipImg = res.returnValue.imageURL;

          this.headerBgc = res.returnValue.headerBgc;
          this.footerBgc = res.returnValue.footerBgc;
          if (this.colorType == 0) {
            this.headerBgc = "#9D0000";
            this.footerBgc = "#9D0000";
          } else if (this.colorType == 1) {
            this.headerBgc = "#F0B222";
            this.footerBgc = "#F0B222";
          }
          this.totalPage = res.returnValue.pager.totalPage;
          this.goodsList = res.returnValue.pager.data;
        } else if (res.code === 210) {
          this.$toast({
            message: res.message,
            duration: 1500,
            onClose: () => {
              this.$router.back();
            },
          });
        }
      });
    },
    // 拼接数据 下拉触发
    cancatData() {
      this.currentPage++;
      if (this.currentPage > this.totalPage) {
        this.loading = false;
        return (this.finished = true);
      }
      const FLAG = Boolean(this.$route.query.categoryId); // 主题精选
      const img = Boolean(this.$route.query.categoryId);
      const Vert = Boolean(this.$route.query.doubleTwelve); // 年货街
      const isMansion = this.$route.query.pageName === "公馆分类"; // 进口好物三级界面
      let categoryType = this.$route.params.categoryType;
      let categoryId = this.$route.params.categoryType;
      let requestAddress = "";
      // 请求地址
      if (Vert) {
        requestAddress = "newYearThirdAddress";
        this.vertical = false;
      } else if (FLAG) {
        requestAddress = "topicSelectSecondAddress";
        this.vertical = false;
      } else if (isMansion) {
        // 进口好货三级
        this.$api.home
          .goodsListAddress({
            categoryId,
            currentPage: this.currentPage,
          })
          .then((res) => {
            if (res.code === 100) {
              this.loading = false;
              this.goodsList = this.goodsList.concat(res.returnValue.data);
            } else {
              this.error = true;
            }
          });
        return false;
      } else {
        switch (categoryType) {
          case "14":
            requestAddress = "importGoodsSecondAddress";
            this.vertical = true;
            break;
          case "10":
          case "6":
          case "17":
          case "19":
          case "9":
            requestAddress = "goodsByTypeAddress";
            this.vertical = false;
            break;
          case "11":
            requestAddress = "tourismAddress";
            this.vertical = false;
            break;
        }
      }
      // 获取第一页之后的数据
      this.$server[requestAddress]({
        categoryType,
        categoryId,
        currentPage: this.currentPage,
        accessToken: localStorage.getItem("token"),
      }).then((res) => {
        if (res.code === 100) {
          this.loading = false; // 关闭上拉加载中
          this.goodsList = this.goodsList.concat(res.returnValue.pager.data);
        } else {
          this.error = true;
        }
      });
    },
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        // this.cancatData();
        this.getNewCategoryList();
      }, 1000);
    },
    getNewCategoryList() {
      if (this.pageNo > this.totalPage) {
        this.loading = false;
        // 恢复pageNo和totalPage的值，以供分类分页使用
        return (this.finished = true);
      }
      //console.log("this.$route", this.$route);
      this.$api.home
        .getGoodsPageAddress({
          categoryId: this.$route.params.categoryType,
          pageNo: this.pageNo,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.isLoading = false;
            this.totalPage = res.data.totalPageNo;
            this.loading = false; // 关闭上拉加载中
            if (this.pageNo === 1) {
              this.goodsList = res.data.items;
            } else {
              this.goodsList = this.goodsList.concat(res.data.items);
            }
            //console.log("this.goodsList列表", this.goodsList);
            this.pageNo++;
          } else {
            this.error = true;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
@red: #e94c67;
.categroy-img-goods {
  padding-top: 0.88rem;
  box-sizing: border-box;
  // background-color: @red;
  min-height: 100vh;

  width: 100%;
  /deep/.van-nav-bar {
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s linear;
    .van-icon {
      font-size: 0.4rem;
      color: #333;
    }
    .van-nav-bar__title {
      color: #fff;
      letter-spacing: 2px;
    }
  }
  .categroy-mansion {
    // position: relative;
    // top: 46px;
    width: 100%;
    box-sizing: border-box;
    // padding: 0 0.12rem /* 6/50 */;
    margin-bottom: -0.5rem /* 25/50 */;
    .categroy-mansion-img {
      display: flex;
      p {
        // padding: 0 0.12rem /* 6/50 */;
        img {
          width: 100%;
        }
      }
    }
    .categroy-mansion-title {
      text-align: center;
      color: #239cd5;
      font-size: 0.38rem /* 19/50 */;
      font-weight: bold;
      letter-spacing: 0.4em;
      line-height: 2.4;
    }
  }
  .tip-img {
    width: 100%;
    margin-bottom: 0.16rem /* 8/50 */;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .van-list {
    margin-top: 0.64rem /* 32/50 */;
    padding: 0 0.12rem /* 6/50 */;
    &.vertival {
      padding: 0 0.24rem /* 12/50 */;
    }
    .van-col {
      margin-bottom: 0.28rem /* 14/50 */;
      ul {
        background-color: rgb(255, 255, 255);
        height: 4.8rem /* 240/50 */;
        box-sizing: border-box;
        font-size: 0.28rem /* 14/50 */;
        padding: 0 0 0.24rem /* 12/50 */;
        border-radius: 0.12rem /* 6/50 */;
        li {
          padding: 0 0.16rem /* 8/50 */;
          line-height: 0.34rem /* 17/50 */;
          color: #544f4f;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: rows;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          &:first-child {
            padding: 0;
            height: 3.2rem /* 160/50 */;
          }
          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 0.12rem /* 6/50 */;
            border-top-right-radius: 0.12rem /* 6/50 */;
          }
        }
      }
    }
    .goodslist-row {
      padding: 0;
      /deep/ .van-card {
        box-shadow: none;
      }
    }
    /deep/ .van-list__finished-text {
      color: #fff;
    }
  }
}
/deep/ .van-nav-bar__content {
  background-color: #fff;
}
/deep/ .van-nav-bar__title {
  color: #333333 !important;
}
// /deep/ .categroy-img-goods .van-nav-bar .van-icon{
//   font-size: .4rem;
//   color: #333;
// }
.showPriceStyle {
  font-size: 0.3rem;
  color: @price_text_color;
  margin-right: 0.06rem;
}
</style>